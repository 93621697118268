import { Workbook } from '@syncfusion/ej2-excel-export';
import * as events from '../../common/base/constant';
import { isNullOrUndefined } from '@syncfusion/ej2-base';
import { PivotUtil } from '../../base/util';
/**
 * @hidden
 * `ExcelExport` module is used to handle the Excel export action.
 */
var ExcelExport = /** @class */ (function () {
    /**
     * Constructor for the PivotGrid Excel Export module.
     * @param {PivotView} parent - Instance of pivot table.
     * @hidden
     */
    function ExcelExport(parent) {
        this.actualrCnt = 0;
        this.parent = parent;
    }
    /**
     * For internal use only - Get the module name.
     * @returns {string} - string.
     * @private
     */
    ExcelExport.prototype.getModuleName = function () {
        return 'excelExport';
    };
    ExcelExport.prototype.addHeaderAndFooter = function (excelExportProperties, stringValue, type, rowCount) {
        var cells = [];
        if (!isNullOrUndefined(excelExportProperties.rows)) {
            this.actualrCnt = (type === 'footer') ? this.actualrCnt + rowCount - (excelExportProperties.rows[0].cells.length) : this.actualrCnt;
            var row = excelExportProperties.rows;
            for (var i = 0; i < row.length; i++) {
                for (var j = 0; j < row[i].cells.length; j++) {
                    cells = [];
                    cells.push({
                        index: i + 1, value: row[i].cells[j].value,
                        colSpan: row[i].cells[j].colSpan, rowSpan: row[i].cells[j].rowSpan, style: row[i].cells[j].style
                    });
                    this.actualrCnt++;
                    this.rows.push({ index: this.actualrCnt, cells: cells });
                }
            }
            this.actualrCnt = (type === 'header') ? rowCount : this.actualrCnt;
        }
        else {
            if (stringValue !== '') {
                if (type === 'footer') {
                    this.actualrCnt++;
                }
                cells.push({
                    index: 1, value: stringValue,
                });
                this.rows.push({ index: this.actualrCnt + 1, cells: cells });
                this.actualrCnt = (type === 'header') ? this.actualrCnt + 2 : this.actualrCnt;
            }
        }
    };
    /* eslint-disable */
    /**
     * Method to perform excel export.
     * @hidden
     */
    ExcelExport.prototype.exportToExcel = function (type, exportProperties) {
        this.rows = [];
        this.actualrCnt = 0;
        var isHeaderSet = !isNullOrUndefined(exportProperties) && !isNullOrUndefined(exportProperties.header);
        var isFooterSet = !isNullOrUndefined(exportProperties) && !isNullOrUndefined(exportProperties.footer);
        var isFileNameSet = !isNullOrUndefined(exportProperties) && !isNullOrUndefined(exportProperties.fileName);
        this.engine = this.parent.dataType === 'olap' ? this.parent.olapEngineModule : this.parent.engineModule;
        /** Event trigerring */
        var clonedValues;
        var currentPivotValues = PivotUtil.getClonedPivotValues(this.engine.pivotValues);
        var customFileName = isFileNameSet ? exportProperties.fileName : 'default.xlsx';
        if (isHeaderSet) {
            this.addHeaderAndFooter(exportProperties.header, '', 'header', exportProperties.header.headerRows);
        }
        if (this.parent.exportAllPages && this.parent.enableVirtualization && this.parent.dataType !== 'olap') {
            var pageSettings = this.engine.pageSettings;
            this.engine.pageSettings = null;
            this.engine.generateGridData(this.parent.dataSourceSettings, true);
            this.parent.applyFormatting(this.engine.pivotValues);
            clonedValues = PivotUtil.getClonedPivotValues(this.engine.pivotValues);
            this.engine.pivotValues = currentPivotValues;
            this.engine.pageSettings = pageSettings;
        }
        else {
            clonedValues = currentPivotValues;
        }
        var args = {
            fileName: customFileName, header: '', footer: '', dataCollections: [clonedValues]
        };
        var fileName;
        var header;
        var footer;
        var dataCollections;
        this.parent.trigger(events.beforeExport, args, function (observedArgs) {
            fileName = observedArgs.fileName;
            header = observedArgs.header;
            footer = observedArgs.footer;
            dataCollections = observedArgs.dataCollections;
        });
        if (!isHeaderSet && isNullOrUndefined(args.excelExportProperties) && header !== '') {
            this.addHeaderAndFooter({}, header, 'header', undefined);
        }
        else if (!isNullOrUndefined(args.excelExportProperties) && !isNullOrUndefined(args.excelExportProperties.header)) {
            this.addHeaderAndFooter(args.excelExportProperties.header, '', 'header', args.excelExportProperties.header.headerRows);
        }
        /** Fill data and export */
        var workSheets = [];
        for (var dataColl = 0; dataColl < dataCollections.length; dataColl++) {
            var pivotValues = dataCollections[dataColl];
            var colLen = 0;
            var rowLen = pivotValues.length;
            var formatList = this.parent.renderModule.getFormatList();
            var maxLevel = 0;
            for (var rCnt = 0; rCnt < rowLen; rCnt++) {
                if (pivotValues[rCnt]) {
                    this.actualrCnt++;
                    colLen = pivotValues[rCnt].length;
                    var cells = [];
                    for (var cCnt = 0; cCnt < colLen; cCnt++) {
                        if (pivotValues[rCnt][cCnt]) {
                            var pivotCell = pivotValues[rCnt][cCnt];
                            if (!(pivotCell.level === -1 && !pivotCell.rowSpan)) {
                                var cellValue = pivotCell.axis === 'value' ? pivotCell.value : pivotCell.formattedText;
                                if (pivotCell.type === 'grand sum') {
                                    cellValue = this.parent.localeObj.getConstant('grandTotal');
                                }
                                else if (pivotCell.type === 'sum') {
                                    cellValue = cellValue.toString().replace('Total', this.parent.localeObj.getConstant('total'));
                                }
                                else {
                                    cellValue = cellValue;
                                }
                                if (!(pivotCell.level === -1 && !pivotCell.rowSpan)) {
                                    cells.push({
                                        index: cCnt + 1, value: cellValue,
                                        colSpan: pivotCell.colSpan, rowSpan: (pivotCell.rowSpan === -1 ? 1 : pivotCell.rowSpan),
                                    });
                                    if (pivotCell.axis === 'value') {
                                        if (isNaN(pivotCell.value) || pivotCell.formattedText === '' ||
                                            pivotCell.formattedText === undefined || isNullOrUndefined(pivotCell.value)) {
                                            cells[cells.length - 1].value = type === 'Excel' ? null : '';
                                        }
                                        var field = (this.parent.dataSourceSettings.valueAxis === 'row' &&
                                            this.parent.dataType === 'olap' && pivotCell.rowOrdinal &&
                                            this.engine.tupRowInfo[pivotCell.rowOrdinal]) ?
                                            this.engine.tupRowInfo[pivotCell.rowOrdinal].measureName :
                                            pivotCell.actualText;
                                        cells[cells.length - 1].style = !isNullOrUndefined(cells[cells.length - 1].value) ? { numberFormat: formatList[field], bold: false, wrapText: true } : { bold: false, wrapText: true };
                                        if (pivotCell.style) {
                                            cells[cells.length - 1].style.backColor = pivotCell.style.backgroundColor;
                                            cells[cells.length - 1].style.fontColor = pivotCell.style.color;
                                            cells[cells.length - 1].style.fontName = pivotCell.style.fontFamily;
                                            cells[cells.length - 1].style.fontSize = Number(pivotCell.style.fontSize.split('px')[0]);
                                        }
                                    }
                                    else {
                                        cells[cells.length - 1].style = {
                                            bold: true, vAlign: 'Center', wrapText: true, indent: cCnt === 0 ? pivotCell.level * 10 : 0
                                        };
                                        if (pivotCell.axis === 'row' && cCnt === 0) {
                                            cells[cells.length - 1].style.hAlign = 'Left';
                                            if (this.parent.dataType === 'olap') {
                                                var indent = this.parent.renderModule.indentCollection[rCnt];
                                                cells[cells.length - 1].style.indent = indent * 2;
                                                maxLevel = maxLevel > indent ? maxLevel : indent;
                                            }
                                            else {
                                                var levelName = pivotCell.valueSort ? pivotCell.valueSort.levelName.toString() : '';
                                                var memberPos = pivotCell.actualText ?
                                                    pivotCell.actualText.toString().split(this.parent.dataSourceSettings.valueSortSettings.headerDelimiter).length : 0;
                                                var levelPosition = levelName.split(this.parent.dataSourceSettings.valueSortSettings.headerDelimiter).length -
                                                    (memberPos ? memberPos - 1 : memberPos);
                                                var level = levelPosition ? (levelPosition - 1) : 0;
                                                cells[cells.length - 1].style.indent = level * 2;
                                                maxLevel = level > maxLevel ? level : maxLevel;
                                            }
                                        }
                                    }
                                    cells[cells.length - 1].style.borders = { color: '#000000', lineStyle: 'Thin' };
                                }
                            }
                            cCnt = cCnt + (pivotCell.colSpan ? (pivotCell.colSpan - 1) : 0);
                        }
                        else {
                            cells.push({
                                index: cCnt + 1, value: '', colSpan: 1, rowSpan: 1,
                            });
                        }
                    }
                    this.rows.push({ index: this.actualrCnt, cells: cells });
                }
            }
            if (isFooterSet) {
                this.addHeaderAndFooter(exportProperties.footer, '', 'footer', exportProperties.footer.footerRows);
            }
            else if (!isFooterSet && footer !== '' && isNullOrUndefined(args.excelExportProperties)) {
                this.addHeaderAndFooter({}, footer, 'footer', undefined);
            }
            else if (!isNullOrUndefined(args.excelExportProperties) && !isNullOrUndefined(args.excelExportProperties.footer)) {
                this.addHeaderAndFooter(args.excelExportProperties.footer, '', 'footer', args.excelExportProperties.footer.footerRows);
            }
            var columns = [];
            for (var cCnt = 0; cCnt < colLen; cCnt++) {
                columns.push({ index: cCnt + 1, width: 100 });
            }
            if (maxLevel > 0) {
                columns[0].width = 100 + (maxLevel * 20);
            }
            workSheets.push({ columns: columns, rows: this.rows });
        }
        var book = new Workbook({ worksheets: workSheets }, type === 'Excel' ? 'xlsx' : 'csv', undefined, this.parent.currencyCode);
        if ('.xlsx' === fileName.substring(fileName.length - 5, fileName.length) || '.csv' === fileName.substring(fileName.length - 4, fileName.length)) {
            book.save(fileName);
        }
        else {
            book.save(fileName + (type === 'Excel' ? '.xlsx' : '.csv'));
        }
    };
    /**
     * To destroy the excel export module
     * @returns {void}
     * @hidden
     */
    ExcelExport.prototype.destroy = function () {
    };
    return ExcelExport;
}());
export { ExcelExport };
